import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import Header from '../components/header'
import Footer from '../components/footer'
import facebooklogo from '../assets/images/icon/facebook.svg'
import linkedinlogo from '../assets/images/icon/linkedin.svg'
import knsicongrey from '../assets/images/icon/kns-icon-grey.svg'
import knsblue from '../assets/images/logo/kns-blue.svg'
import $ from 'jquery'
import LocalizedLink from '../LocalizedLink'

class Cookie extends React.Component {
  componentDidMount() {
    if (window.location.pathname === '/cookie') {
      $('.logo a img').attr('src', knsblue)
      $('.menu ul li a').css('color', '#1b437b')
      $('.header').css('background', '#ffffff')
      $('.language').css('border-color', '#1b437b')
      $('.language a span').css('color', '#1b437b')
      $('.other-page-social').show(100)
      $('#nav-toggle span').addClass('colorline')
      $('.numbers h1,.numbers h4').css('color', '#1b437b')
    }
  }
  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="other-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>
          <div className="mobile-menu">
            <div className="close">
              <img src={require('../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>
          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <section className="page">
            <div className="container">
              <div className="col-12">
                <figure className="kns-grey-icon">
                  <img src={knsicongrey} />
                </figure>
                <div className="page-prp">
                  <h1>
                  Privacy & Cookie Policy
                  </h1>
                  <div className="pr-content">
                    {this.props.pageContext.locale == 'tr' && (
                      <>
                        <h2>
                          Gizlilik Sözleşmesi ve Web Sitemizin Kullanımı
                          Hakkında Hakları
                        </h2>
                        <p>
                          www.kns.com.tr web sitesi ve bu internet sitesinde yer
                          alan içerik, görsel ve işitsel unsurlar ve bunların
                          düzenlenmeleri üzerindeki her türlü fikri ve sınai
                          mülkiyet hakkı, aksi açıkça belirtilmediği sürece KNS
                          Bilişim Teknolojileri San. ve Tic. Ltd Şti’ye aittir.
                          Bilgiler, KNS’nin yazılı izni olmaksızın dağıtılamaz,
                          kiralanamaz, çoğaltılamaz, değiştirilemez, ticari bir
                          amaçla kullanılamaz. Ancak kaynak gösterilerek alıntı
                          yapılabilir.
                        </p>
                        <p>
                          KNS, hiçbir koşulda, içerikte olabilecek hata veya
                          eksiklikler veya web sitesinde bulunan içeriğin
                          kullanımından kaynaklanabilecek herhangi bir kayıp
                          veya hasar dahil, fakat bunlarla kısıtlı olmamak
                          kaydıyla, içerikten ve ziyaretçi tarafından sunulan
                          yanlış bilgiden sorumlu tutulamaz.
                        </p>
                        <p>
                          KNS web sitemizde, kendisinin veya üçüncü kişilerin
                          hizmetleri hakkında reklam ve promosyonlar
                          görüntülenebilir. Web sitemizde KNS’ye ait olmayan ve
                          faaliyetleriyle ilgili bulunmayan diğer web sitelerine
                          yönlendirme yapılabilir. Ziyaretçinin bu web
                          sitelerinden herhangi birini ziyaret etmesi halinde
                          üçüncü kişilerin kullanımında ve aitliğinde olan bu
                          web sitelerinin politika ve uygulamalarından KNS
                          sorumlu değildir.
                        </p>
                        <p>
                          Bu internet sitesinde verilen bilgiler, "olduğu gibi",
                          "mevcut olduğu şekilde" sağlanmaktadırlar. KNS,
                          ziyaretçiye, üçüncü kişiler hakkında olanlar da dahil
                          web sitesinde yer alan bilgilerle da dahil olarak
                          mülkiyet, satılabilirlik, doğruluk, güvenilirlik,
                          kesinlik, belirli bir amaç için uygunluk ve/veya
                          bilgisayar virüsü bulunmamasına ilişkin garantiler
                          dahil ancak bunlarla sınırlı kalmamak kaydıyla,
                          zımnen, açıkça ya da yasal olarak hiçbir garanti
                          vermemektedir.
                        </p>
                        <p>
                          Bu internet sitesinin kullanımı sırasında veya
                          sonrasında oluşabilecek herhangi bir performans
                          arızası, hata, eksiklik, kesinti, kusur, işletme
                          ve/veya nakilde gecikme, bilgisayar virüsü ve/veya hat
                          ve/veya sistem arızası sonucu ortaya çıkan doğrudan ya
                          da dolaylı ortaya çıkan zarar, ziyan ve masraflar
                          dâhil ancak bunlarla sınırlı olmamak üzere hiçbir
                          zarar ve ziyandan, KNS ve/veya çalışanları, bu tür bir
                          zarar ve ziyan olasılığından haberdar edilmiş olsalar
                          dahi, sorumlu olmazlar.
                        </p>
                        <p>
                          KNS, bu internet sitesinde yer alan bütün ürün ve
                          hizmetleri, sayfaları, bilgileri, görsel unsurları,
                          içeriği önceden bildirimde bulunmadan değiştirme
                          hakkını saklı tutar.
                        </p>
                        <p>
                          KNS internet sitesi içerisinde sizden şahsi bilgi
                          girişi talep edildiği takdirde, vereceğiniz şahsi
                          bilgileriniz (isim, soyisim, adres, doğum tarihi,
                          e-mail, telefon vb) sadece bilgilendirme iletileri
                          göndermek amacıyla KNS tarafından kullanılacak ve
                          hukuki zorunluluklar veya izniniz haricinde kesinlikle
                          3. şahıs ve kurumlarla paylaşılmayacaktır.
                        </p>
                        <p>
                          Tarafınıza KNS tarafından gönderilen doğrudan
                          pazarlama iletilerini almak istemiyorsanız, bu
                          talebinizi, size gönderilen her bir iletinin altında
                          yer alan “listeden ayrılma” uzantısı üzerinden
                          tarafımıza gönderebilir ya da iletişim bilgilerimizden
                          bize ulaşarak talepte bulunabilirsiniz.
                        </p>
                        <p>
                          KNS web sitemizin hukuki şartları ve gizlilik hakkında
                          daha fazla bilgi almak, sitemizle ilgili görüş ve
                          eleştirilerinizi bildirmek için bize e-posta yoluyla
                          ulaşabilirsiniz.
                        </p>
                      </>
                    )}
                    {this.props.pageContext.locale == 'en' && (
                      <>
                        <h2>
                          Gizlilik Sözleşmesi ve Web Sitemizin Kullanımı
                          Hakkında Hakları
                        </h2>
                        <p>
                          www.kns.com.tr web sitesi ve bu internet sitesinde yer
                          alan içerik, görsel ve işitsel unsurlar ve bunların
                          düzenlenmeleri üzerindeki her türlü fikri ve sınai
                          mülkiyet hakkı, aksi açıkça belirtilmediği sürece KNS
                          Bilişim Teknolojileri San. ve Tic. Ltd Şti’ye aittir.
                          Bilgiler, KNS’nin yazılı izni olmaksızın dağıtılamaz,
                          kiralanamaz, çoğaltılamaz, değiştirilemez, ticari bir
                          amaçla kullanılamaz. Ancak kaynak gösterilerek alıntı
                          yapılabilir.
                        </p>
                        <p>
                          KNS, hiçbir koşulda, içerikte olabilecek hata veya
                          eksiklikler veya web sitesinde bulunan içeriğin
                          kullanımından kaynaklanabilecek herhangi bir kayıp
                          veya hasar dahil, fakat bunlarla kısıtlı olmamak
                          kaydıyla, içerikten ve ziyaretçi tarafından sunulan
                          yanlış bilgiden sorumlu tutulamaz.
                        </p>
                        <p>
                          KNS web sitemizde, kendisinin veya üçüncü kişilerin
                          hizmetleri hakkında reklam ve promosyonlar
                          görüntülenebilir. Web sitemizde KNS’ye ait olmayan ve
                          faaliyetleriyle ilgili bulunmayan diğer web sitelerine
                          yönlendirme yapılabilir. Ziyaretçinin bu web
                          sitelerinden herhangi birini ziyaret etmesi halinde
                          üçüncü kişilerin kullanımında ve aitliğinde olan bu
                          web sitelerinin politika ve uygulamalarından KNS
                          sorumlu değildir.
                        </p>
                        <p>
                          Bu internet sitesinde verilen bilgiler, "olduğu gibi",
                          "mevcut olduğu şekilde" sağlanmaktadırlar. KNS,
                          ziyaretçiye, üçüncü kişiler hakkında olanlar da dahil
                          web sitesinde yer alan bilgilerle da dahil olarak
                          mülkiyet, satılabilirlik, doğruluk, güvenilirlik,
                          kesinlik, belirli bir amaç için uygunluk ve/veya
                          bilgisayar virüsü bulunmamasına ilişkin garantiler
                          dahil ancak bunlarla sınırlı kalmamak kaydıyla,
                          zımnen, açıkça ya da yasal olarak hiçbir garanti
                          vermemektedir.
                        </p>
                        <p>
                          Bu internet sitesinin kullanımı sırasında veya
                          sonrasında oluşabilecek herhangi bir performans
                          arızası, hata, eksiklik, kesinti, kusur, işletme
                          ve/veya nakilde gecikme, bilgisayar virüsü ve/veya hat
                          ve/veya sistem arızası sonucu ortaya çıkan doğrudan ya
                          da dolaylı ortaya çıkan zarar, ziyan ve masraflar
                          dâhil ancak bunlarla sınırlı olmamak üzere hiçbir
                          zarar ve ziyandan, KNS ve/veya çalışanları, bu tür bir
                          zarar ve ziyan olasılığından haberdar edilmiş olsalar
                          dahi, sorumlu olmazlar.
                        </p>
                        <p>
                          KNS, bu internet sitesinde yer alan bütün ürün ve
                          hizmetleri, sayfaları, bilgileri, görsel unsurları,
                          içeriği önceden bildirimde bulunmadan değiştirme
                          hakkını saklı tutar.
                        </p>
                        <p>
                          KNS internet sitesi içerisinde sizden şahsi bilgi
                          girişi talep edildiği takdirde, vereceğiniz şahsi
                          bilgileriniz (isim, soyisim, adres, doğum tarihi,
                          e-mail, telefon vb) sadece bilgilendirme iletileri
                          göndermek amacıyla KNS tarafından kullanılacak ve
                          hukuki zorunluluklar veya izniniz haricinde kesinlikle
                          3. şahıs ve kurumlarla paylaşılmayacaktır.
                        </p>
                        <p>
                          Tarafınıza KNS tarafından gönderilen doğrudan
                          pazarlama iletilerini almak istemiyorsanız, bu
                          talebinizi, size gönderilen her bir iletinin altında
                          yer alan “listeden ayrılma” uzantısı üzerinden
                          tarafımıza gönderebilir ya da iletişim bilgilerimizden
                          bize ulaşarak talepte bulunabilirsiniz.
                        </p>
                        <p>
                          KNS web sitemizin hukuki şartları ve gizlilik hakkında
                          daha fazla bilgi almak, sitemizle ilgili görüş ve
                          eleştirilerinizi bildirmek için bize e-posta yoluyla
                          ulaşabilirsiniz.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </body>
      </Layout>
    )
  }
}
Cookie.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export default Cookie
